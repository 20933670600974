$primary: #0679d1;
$success: #00a57c;
$waring: #edcf33;
$danger: #cf0a0a;
$secondary: #bdbdbd;
$dark: #333333;

// Font
$body-color: $dark;
$font-weight-bold: 600;

// Input
$input-focus-border-color: $success;
$input-focus-width: 1px;
$input-focus-box-shadow: none;

// Pagination
$pagination-focus-box-shadow: none;
$pagination-border-color: $success;
$pagination-color: $success;
$pagination-focus-color: $success;
$pagination-hover-color: $success;
$pagination-focus-outline: 0;

// Switch
$form-switch-focus-color: $secondary;
$form-check-input-focus-border: $success;
$form-check-input-focus-box-shadow: none;
$form-check-input-checked-bg-color: $success;
$form-check-input-checked-color: $secondary;

// Table
$table-accent-bg: unset;

// Card
$card-border-radius: 15px;

// Botton
$btn-border-radius: 5px;

// QuickAdmin Setup
@import "../assets/css/bootstrap-datetimepicker.min.css";
@import "../assets/css/dropzone.min.css";
@import "../assets/css/perfect-scrollbar.min.css";
@import "../assets/css/select2.min.css";
@import "../assets/css/select2-bootstrap-5-theme.min.css";

// jQuery Datatables
@import "~datatables.net-bs5/css/dataTables.bootstrap5.min.css";
@import "~datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css";
@import "~datatables.net-select-bs5/css/select.bootstrap5.min.css";

// Icon
@import "@fortawesome/fontawesome-free/css/all.min.css";

// Swal Alert
@import "sweetalert2/dist/sweetalert2.min.css";

// Core Ui & Bootstrap
@import "@coreui/coreui/scss/coreui";
@import "~bootstrap/scss/bootstrap";
